@import '../../theme.scss';
#jeloles {
	background-color: $iok-lightblue;
	h2.title {
		color: $dark;
	}
	.text {
		color: $dark;
	}
	h3 {
		text-align: center;
		color: $dark;
	}
	p.subtitle{
		margin-bottom: 0;
	}
	.reg-form {
		max-width: 700px;
		margin: auto;
		input, textarea {
			margin-bottom: 18px;
		}
	}
	.form-label, .form-check-label {
		color: $dark;
		font-size: 0.90rem;
		font-weight: 600;		
	}
	.form-check-label a {
		color: #000;
		text-decoration: underline;
	}
	p {
		color: $dark;
		font-size: 0.90rem;
	}
	.form-check-input:checked {
		background-color: $primary;
		border-color: adjust-color($color: $primary, $lightness: -15%);
	}
	.form-check-input:focus {
		box-shadow: none;
	}
	.form-check {
	}
	.form-control, .form-select {
		&:focus {
			box-shadow: 0 0 0 0.1rem $primary;
			border-color: $primary;
		}
		&.is-invalid:focus {
			box-shadow: 0 0 0 2px #dc3545 !important;
			border-color: #dc3545 !important;
		}
	}
	input, select, select option {
		font-family: sans-serif;
	}
	.alert {
		font-size: 0.9rem;
		line-height: 0.9;
		font-weight: 600;
		padding: 1.05rem 1rem 0.9rem 1rem;
	}
	button {
		position: relative;
	}
}

.modal {
	color: $dark;
	.modal-title.h4 {
		font-size: 1.2rem;
		font-weight: 600;
	}
	.modal-body {
		display: flex;
		flex-direction: column;
		font-size: 0.92rem;
		.escape-room-reg-button {
			margin: 0 auto;
		}
	}

}

hr {
	border: 0;
	height: 1px;
	width: 100%;
	flex-shrink: 0;
	background-color: $dark;
	opacity: .25;
	margin-top: .5rem;
}