@import '../../theme.scss';

#welcome {
	
	padding: 120px 0 90px 0;
	min-height: 655px;

	&.small {
		min-height: 400px;
		.welcome-image {
			max-height: 280px;
		}
	}



	.welcome-image {
		position: absolute;
		top: 120px;
		right:3%;
		max-width: 35%;
		max-height: 475px;
		z-index: 7;	
		@include mobile {
			display: none;
		}
	}
	.welcome-image-mobile {
		display: none;
		width: 100%;
		margin-bottom: 20px;
		@include mobile {
			display: block;
		}
	}
	.bubbles {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		max-width: 560px;
		gap: 10px;
	}
	.overview {		
		margin: 35px 0 25px 0;
	}
	h1 {
		font-weight: 700;
		font-size: 2.7rem;
		line-height: 1.5;
		color: $light;
		.highlight {
			color: $primary;
		}
		@include mobile {
			font-size: 2rem;
			text-align: center;
		}
	}
	p {
		color: $primary;
		font-size: 0.9rem;
		line-height: 1.8;
		margin: 10px 0 30px 0;
	}
	.buttons {
		>div {
			margin-right: 20px;
			display: inline-block;
		}
	}
	@include mobile {
		padding: 90px 0 75px 0;
		.title {
			padding-top: 10px;
		}
		.subtitle {
			margin-top: -10px;
		}
	}
	.title {
		color: $primary;
	}
	.title1 {
		font-size: 1.5rem;
		font-weight: 700;
		margin: 2rem 0 0.5rem 0;
	}
	.title2 {
		font-size: 2.3rem;
		font-weight: 300;
		margin: 0 0 0.5rem 0;
	}
	.title3 {
		font-size: 3rem;
		font-weight: 300;
		margin: 0 0 2rem 0;
	}
}

.overview {
	.overview-item {
		display: inline-block;
		font-size: 0.85rem;
		font-weight: 600;
		color: $primary;
		margin-right: 20px;
		svg {
			fill: $primary;
			height: 15px;
			transform: translateY(-2.5px);
			margin-right: 8px;
		}
		@include mobile {
			display: block;
			text-align: center;
			margin: 10px 0;
		}
	}
}