@import '../../theme.scss';

section.section {
	&.section-placeholder {
		min-height: 300px;
		margin: auto;
	}
	&:nth-of-type(even) {
		background-color: $bg-primary;
	}
	&:nth-of-type(odd) {
		background-color: $bg-secondary;
	}
	padding: 140px 0;
	@include mobile {
		padding: 70px 0;
	}
	.background {
		background-color: white;
		width: 100vw;
	}

	.background-strip {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 1;
	}

	.zero-strip {
		left: 0;
		background-color: white;
		width: 100%;
	}

	.image-negyedgomb {
		left: 0;
		z-index: 2;
		& img {
			width: 45%;
			height: auto;
			opacity: 0.5;
		}
	}
	.image-felgomb {
		left: 0;
		top: 60%;
		z-index: 2;
		& img {
			width: 50%;
			height: auto;
			opacity: 0.5;
		}
	}
	.first-strip {
		left: 0;
		background-color: #a8e8f8;            
		width: 55%;
	}

	.second-strip {
		left: 55%;
		background-color: #c1effa;
		width: 50px;
	}
	.third-strip {
		left: calc(55% + 50px);
		background-color: #def6fc;
		width: 50px;
	}
}