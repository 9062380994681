@import '../theme.scss';

footer {
	background-color: $primary;
	padding: 40px 0;
	.container {
		display: flex;
		@include mobile {
			display: block;
		}
	}
	.links {
		flex: 1;
		color: $lightgrey;
		font-weight: 500;
		font-size: 0.8rem;

		div.title {
			font-weight: 700;
			font-size: 1.2rem;
			margin-bottom: 10px;
		}

		div.contact {
			display: grid;
			grid-template-columns: 16px auto;
			gap: 5px;
			@include mobile {
				margin-bottom: 20px;
			}
		}

		svg {
			fill: $lightgrey;
			transform: translateY(-2px);
			width: 16px;
		}
		
		a {
			@include mobile {
			}
			text-decoration: none;
			color: $lightgrey;
			margin-right: 20px;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.copyright {
		color: $grey;
		font-size: 0.8rem;
	}
	.http {
		filter: invert(0.6);
		height: 50px;
	}
	.copy {
		margin-top: 10px;
	}
	@include mobile {
		padding: 40px 20px;
		.copyright {
			margin-top: 20px;
		}
	}
}