@import '../../theme.scss';
#netacad-oktatasert-dijak {

	.sponsor-grid {
		display: grid;
/* 		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 1fr); */
		padding: 20px;
		column-gap: 2%;
		row-gap: 50px;
		max-width: 1100px;
		margin: 0 auto;
		.sponsor {
			width: 100%;
			img {
				max-width: 280px;
				max-height: 100px;
			}
			@include mobile {
				padding: 80px;
			}
		}
		background-color: rgba(222, 246, 252, 0.8);

		&.main-sponsors, &.partner-sponsors {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
			max-width: 1000px;
			align-items: center;
			width: 100%;
			text-align: center;
			
			.sponsor {
				height: 90px;
			}
			@include mobile {
				grid-template-columns: 1fr;
				column-gap: 20px;
				row-gap: 50px;
				.sponsor {
					padding: 0;
					text-align: center;
					height: auto;
					img {
						width: 80%;
					}
				}
			}
		}
		&.partner-sponsors {
			height: 180px;
			grid-template-columns: repeat(4, 1fr);
			max-width: 1000px;
			align-items: center;
			gap: 0;
			width: 100%;
			@media screen and (max-width: 1200px) {
				grid-template-columns: 1fr;
				height: auto;
				row-gap: 50px;
			}
/* 			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr); */
			.elte-tok {
				height: 180px !important;
			}
			.sponsor {
				text-align: center;
				height: auto;
				img {
					width: auto !important;
				}

			}
		}
		&.itmp-sponsors {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			text-align: center;
			.sponsor {
				padding: 0
			}

		}

	}

	h3 {
		font-weight: 700;
		font-size: 1.6rem;	
		line-height: 1.5;
		text-align: center;
		margin-bottom: 30px;
		&:nth-of-type(2) {
			margin: 50px 0 50px 0;
		}
		@include mobile {
			font-size: 1.3rem;
			margin-top: 30px;
		}
	}
	padding-bottom: 100px;
}