$primary: #0d274d;
$secondary: #fbab2c;
$light: #fff;
$dark: #0d274d;
$grey: #4F4F4F;
$lightgrey: #BDBDBD;
$bg-light: #fff;
$bg-primary: #0d274d;
$bg-secondary: #a8e8f8; //00bceb; //#def6fc;
$h1-color: #47BED0;
$iok-lightblue: #47BED0;
$iok-light: #d6f4f5;
$iok-lightgrey: #fde9d1;

/* 

$primary: #f9a870;
$light: #fff;
$dark: #003b52;
$grey: #4F4F4F;
$lightgrey: #BDBDBD;
$bg-light: #fff;
$bg-primary: #f9a870;
$bg-secondary: #003b52;
$h1-color: #47BED0;
$iok-lightblue: #47BED0;
$iok-light: #d6f4f5;
$iok-lightgrey: #fde9d1; */

/* 
$bg-primary: #003b52;
1c3664
$primary: #47CCD4;
$light: #fff;
$dark: #000;
$grey: #4F4F4F;
$lightgrey: #BDBDBD;
$bg-light: #fff;
$bg-primary: #D6F4F5;
 */
$mobile-breakpoint: 762px;

@mixin mobile {
	@media screen and (max-width: #{$mobile-breakpoint}) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: #{$mobile-breakpoint}) {
		@content;
	}
}